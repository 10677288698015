



























import Vue from 'vue';
import router from '@/router';

import { IPostFields } from '../../@types/generated/contentful';

export default Vue.extend({
  name: 'Article',
  props: {
    postRef: String,
  },
  data() {
    return {
      loading: false,
      post: ({
        title: '',
        content: {
          content: [],
          data: {},
          nodeType: 'document',
        },
        postDate: '',
        read: '',
        tags: {
          fields: {
            tags: [],
          },
        },
      } as unknown) as IPostFields,
    };
  },
  mounted() {
    this.getPost(this.postRef);
  },
  methods: {
    goToPostView(id: string) {
      router.push({ name: 'post', params: { postId: id } });
    },
    async getPost(id: string) {
      this.loading = true;
      await this.$contentful
        .getEntry<IPostFields>(id)
        .then(data => {
          // console.log(data.fields);
          this.post = data.fields;
          this.loading = false;
          // console.log(this.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
});
