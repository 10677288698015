













import Vue from 'vue';
import { IPostsBlogFields } from '../../@types/generated/contentful';

import Article from '../components/Article.vue';

export default Vue.extend({
  name: 'Blog',
  components: {
    Article,
  },
  data() {
    return {
      data: {} as IPostsBlogFields,
    };
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    async getPosts() {
      await this.$contentful
        .getEntry<IPostsBlogFields>('CPXA5yGOgKNzgYEd59PwM')
        .then(data => {
          // console.log(data.fields);
          return (this.data = data.fields);
          // console.log(this.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
});
